* {
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding:0.7rem;
  background-color:#333;
}

nav a {
  color: #f5f5f5;
  text-decoration: none;
  margin-right:1rem;
  transition: all 0.2s ease-in-out;
}

nav a:hover {
  color: rgba(216, 216, 216, 0.33);
}

.main-name p {
  color: #f5f5f5;
  text-transform: uppercase;
  font-family: 'Poppins',sans-serif;
}