* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

footer {
  display: flex;
  justify-content: center;
  padding:1rem;
  width:100%;
  background: #333;
}

footer p {
  font-size: 1.1rem;
  font-family: 'Poppins',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#f5f5f5;
}