* {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: rgb(43, 0, 135);
  background: linear-gradient(90deg, rgba(59, 0, 185, 1) 0%, rgba(83, 64, 255, 1) 31%, rgba(128, 149, 255, 1) 61%, rgba(255, 107, 137, 1) 100%);
}

.info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  height: auto;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.427);
  overflow: hidden;
  background: rgb(238, 174, 202);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(216, 177, 210, 1) 22%, rgba(197, 180, 216, 1) 51%, rgba(172, 184, 225, 1) 81%, rgba(148, 187, 233, 1) 100%);
}

section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding:2rem;
}

.info-image {
  padding: 0;
}

.info-image img {
  width: 100%;
  border-radius: 20px;
}

.info-text {
  line-height: 2.5rem;
  margin:2rem;
}

.info-text p {
  font-size: 1.15rem;
  font-weight:500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins',sans-serif;
}

.credit {
  padding:1.3rem;
}

.header-name {
  margin-top: 2rem;
  color: rgb(0, 0, 0);
}

aside {
  display : flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem;
}

aside p {
  font-size: 1.2rem;
  margin-right: 2rem;
}